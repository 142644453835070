import React, { useState } from 'react'


import DoctorIcon from "../assets/membercard/DoctorIcon.png"
import { appdata } from '../constants/appSamajdata'
import { doctorList } from '../constants/staticData'
import viweMale from "../assets/final cards for mayur/male matrimony/viewprofilebutton.png"



const { main_heading, Logo, HeadPerson } = appdata
export default function DoctorCard() {
    const [selectedDoctor, setSelectedDoctor] = useState(null);

    const openModal = (doctor) => {
        setSelectedDoctor(doctor);
    };

    const closeModal = () => {
        setSelectedDoctor(null);
    };

    return (
        <>
            {doctorList.map((doctor, index) => (
                <div key={index} className='w-[320px] shadow-2xl m-4 rounded-b-3xl'>
                    <div className='doctor-card-bg-color-bottom relative text-center p-2 w-full h-[70px] rounded-t-3xl'>
                        <h2 className='font-bold'>{main_heading}</h2>
                        <img
                            className="w-[4rem] h-[4rem] left-[40%] absolute ring-2 rounded-full flex-none bg-blue-400"
                            src={'https://tse3.mm.bing.net/th?id=OIP.d91ova9TzxPv09I0cagaRwHaHa&pid=Api&P=0&w=300&h=300'}
                            alt=""
                            loading="lazy"
                        />
                    </div>
                    <div className='comittee-card-bg-color-bottom w-full h-[125px] pt-6 text-center rounded-b-3xl'>
                        <div className='w-full flex flex-col justify-center'>
                            <h1 className='text-[#F7F014] font-semibold'>{doctor.name}</h1>
                            <small className='w-100 px-5 py-[0.1rem] h-[40px] bg-yellow-400 font-semibold'>
                                {doctor.specialization}
                            </small>
                            <img
                                onClick={() => openModal(doctor)}
                                className='ms-32 h-7 w-7 xl:h-9 cursor-pointer xl:w-9'
                                src={viweMale}
                                alt="View"
                            />
                            <img className='mt-[-40px] w-16 h-16' src={DoctorIcon} alt="Doctor Icon" />
                        </div>
                    </div>

                    {/* Modal Component */}
                    {selectedDoctor && selectedDoctor.name === doctor.name && (
                        <Modal isOpen={selectedDoctor !== null} onClose={closeModal}>
                            <h1>{selectedDoctor.name}</h1>
                            <h1>{selectedDoctor.specialization}</h1>
                        </Modal>
                    )}
                </div>
            ))}
        </>
    )
}



const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md mx-auto">
                <div className="flex justify-end p-2">
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-gray-800 focus:outline-none"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="px-6 pb-6">{children}</div>
            </div>
        </div>
    );
};

export { Modal };

