import React, { useEffect, useState } from 'react'
import { searchMemberForMemberByAdmimForActivation, searchMemberForPaymentLaga, updateLaga, updateMemberStatus } from '../../../service/Services';
import { SearchCriteria } from '../../../FormsInputes.js';
import { gotra, years } from "../../../constants/staticData.js"
import axios from 'axios';
import { showErrorAlert, showSuccessAlert } from '../../../Components/ShowAlertCustome.js';
import LoadingModal from '../../../Forms/LoadingModel.js';
import { Pagination } from "./Matrimony.userManagement.js"

export default function LagaManagement() {

    // "_id": "6656cd65ef323d6f5a1abffc",
    // "lagaId": 896,
    // "paymentDateTime": null,
    // "registrationMobileNumber": "7709433561",
    // "memberName": "भोई मयूर",
    // "isLagaPaid": 0,
    // "membershipCode": "MEM00911",
    // "recMembershipCode": null,
    // "Amount": null,
    // "lagaYear": 2024,
    // "__v": 0,
    // "createdAt": "2024-05-29T06:38:29.312Z",
    // "updatedAt": "2024-05-29T06:38:29.312Z"
    const [criteria, setCriteria] = useState({ lagaId: "", registrationMobileNumber: "", membershipCode: "", lagaYear: "", isLagaPaid: "" })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [byName, setByName] = useState([])
    const [occupation, setOccupation] = useState([])
    const [lagaId, setLagaId] = useState([])
    const [membershipCode, setMembershipID] = useState([])
    const [mobileNo, setMobileNo] = useState([])
    const [lagaStatus, setLagaStatus] = useState(['true', 'false',])
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [lagaMember, setLagaMember] = useState([]);
    const totalItemInPage = 10;


    useEffect(() => {
        setLoading(true)
        console.log(currentPage)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                // console.log('criteria', criteria)
                const memberData = await searchMemberForPaymentLaga(criteria, requestOptions)
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
                console.log('searchMemberForMemberPage', memberData);
                if (memberData?.success) {
                    const data = memberData?.data || [];
                    setTotalPages(memberData?.pagination?.totalPages || 1);
                    console.log(data) // Set total pages

                    // setTotalPages(memberData?.pagination?.totalPages);
                    // setPagination(memberData?.pagination)
                    // dispatch(setPagination(memberData?.pagination))
                    console.log(data)
                    setLagaMember(data)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [criteria, currentPage])
    // searchMemberForMemberByAdmimForActivation
    // 1 member type, member it, name gontra, registation date, stustus ,
    // useEffect(() => {
    //     const uniqueNames = [...new Set(lagaMember.map(member => member.name))];
    //     setByName(uniqueNames);
    //     // console.log(uniqueNames)

    //     const uniqueSurnames = [...new Set(lagaMember.map(member => member.surname))];
    //     setLagaId(uniqueSurnames);
    //     // const uniqueGotras = [...new Set(lagaMember.map(member => member.gotra))];
    //     // setGotra(uniqueGotras);
    //     // const uniquesetmembershipID = [...new Set(lagaMember.map(member => member?.membershipCode))];
    //     // setMembershipID(uniquesetmembershipID);

    // }, [lagaMember.length])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        setLagaMember(pre => [])
        setTotalPages(1)
        setCurrentPage(pre => 1)
    }, [criteria])


    return (
        <>
            <LoadingModal show={isLoading} text={'Updating...'} />
            <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">
                <div className="   ">
                    {/* name */}
                    <SearchCriteria
                        // options={byName}
                        search={'recMembershipCode'}
                        id={'recMembershipCode'}
                        setOptions={setCriteria}
                        label={"recMembershipCode"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}

                    />
                </div>

                {/* lagaId */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={lagaId}
                        search={'lagaId'}
                        id={'lagaId'}
                        setOptions={setCriteria}
                        label={"lagaId"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* lagaStatus */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={lagaStatus}
                        search={'isLagaPaid'}
                        id={'isLagaPaid'}
                        setOptions={setCriteria}
                        label={"laga Status"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* gotra */}
                <div className="w-100  ">
                    <SearchCriteria
                        // options={gotra}
                        search={'lagaYear'}
                        id={'lagaYear'}
                        setOptions={setCriteria}
                        label={"lagaYear"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* membershipCode */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={membershipCode}
                        search={'membershipCode'}
                        id={'membershipCode'}
                        setOptions={setCriteria}
                        label={"membershipCode"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* mobileNo */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={mobileNo}
                        search={'registrationMobileNumber'}
                        id={'registrationMobileNumber'}
                        setOptions={setCriteria}
                        label={"मोबाइल नंबर"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>
            </div>
            <div className="px-2  py-4">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="w-full bg-gray-100 text-left">

                                <th className="py-2 px-4 border-b">Member Name</th>
                                <th className="py-2 px-4 border-b">Laga Id</th>
                                <th className="py-2 px-4 border-b">Contact No</th>
                                <th className="py-2 px-4 border-b">Payment DateTime</th>
                                <th className="py-2 px-4 border-b">membershipCode</th>
                                <th className="py-2 px-4 border-b">Laga Year</th>
                                <th className="py-2 px-4 border-b">Laga Amount</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {lagaMember.length != 0 &&
                                lagaMember.map((member, index) => {
                                    return <tr className="hover:bg-gray-100" key={index}>
                                        <CreatTable member={member} index={index + 1} setLoading={setLoading} />
                                    </tr>
                                })
                            }
                            {/* Add more rows as needed */}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />        </>
    )
}

const CreatTable = ({ member, index, setLoading }) => {

    const [year, setYear] = useState("");
    const [amount, setAmount] = useState("");
    const [changeId, setChangeId] = useState('')
    const { lagaId, registrationMobileNumber, membershipCode, paymentDateTime, Amount, lagaYear, recMembershipCode, memberName, isLagaPaid } = member


    const handleYearChange = (event, lagaId) => {
        console.log(event.target.value)
        setYear(event.target.value);
        setChangeId(lagaId)
    };

    const changeStatus = async (changeId) => {

        if (amount < 100) {
            showErrorAlert("Please Add Amount More Than 100")
            return
        }
        if (changeId === member?.lagaId)

            try {

                let isYearChange = year || member?.lagaYear

                setLoading(true)
                const response = await updateLaga({ lagaId: member?.lagaId, lagaYear: isYearChange, Amount: amount, membershipCode: membershipCode });
                console.log("response", response)
                if (response?.success) {
                    showSuccessAlert(response?.message);
                }
            } catch (error) {
                console.error('Error while updating member status:', error);
                // Handle error if necessary
                showErrorAlert(error?.message)
            } finally {
                setLoading(false)
                setYear("")
            }
    };

    return (
        <>
            <td className="py-2 px-4 border-b">{memberName}</td>
            <td className="py-2 px-4 border-b">{lagaId}</td>
            <td className="py-2 px-4 border-b">{registrationMobileNumber}</td>
            <td className="py-2 px-4 border-b">{paymentDateTime && new Date(paymentDateTime).toLocaleString()}</td>

            <td className="py-2 px-4 border-b">{membershipCode}</td>
            <td className="py-2  border-b">
                <select
                    value={year}
                    onChange={(e) => handleYearChange(e, member?.lagaId)}
                    className="border px-6 border-gray-300 rounded p-1"
                >
                    <option value={lagaYear}>{lagaYear}</option>


                    {/* <option value={member?.lagaStatus}>{member?.lagaStatus}</option>
                    <option value="Activated">Activated</option>
                    <option value="Not Activated">Not Activated</option>
                    <option value="Block">Block</option> */}
                    {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </td>

            <td className="py-2 px-4 border-b">
                {Amount && !(changeId === member?.lagaId) ? <p className=' text-center'>{Amount}</p>
                    : <input onChange={(e) => setAmount(e.target.value)} className='px-2' placeholder='Enter Laga Amount' />
                }
            </td>
            <td className="py-2 px-4 border-b">
                {isLagaPaid ? <p className=' font-bold text-green-500' >Paid </p> : <p className=' font-bold text-red-500' >Not Paid</p>}
                {/* <input onChange={(e) => setAmount(e.target.value)} className='px-2' placeholder='Enter Laga Amount' /> */}
            </td>
            <td className="py-2 px-4 border-b">
                <button onClick={() => changeStatus(member?.lagaId)} className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600">
                    Update
                </button>
            </td>
        </>
    );
};
