import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { setUser } from "../Redux/authSlicer";
import { Footer, Header } from "../Components";


const RetailerRout = () => {
    const isAuthenticated = useSelector(state => state?.auth);
    console.log("userType !== 1", isAuthenticated)
    if (isAuthenticated?.isLogin && (isAuthenticated?.user?.userType === 7 || isAuthenticated?.user?.userType === 1)) return <>
        <Header />
        <Outlet />
        <Footer /> </>;
    return <Navigate to="/" />;

};

export { RetailerRout };