
import Logo from "../assets/login card/logo.jpg"
import HeadPerson from "../assets/login card/headPerson.png"


export const appdata = {
    main_heading: "Ayush Club",
    swagat_sms: "Welcome to the Ayush Club",
    Logo: Logo,
    HeadPerson
}
