import React, { memo, useEffect, useMemo, useState } from 'react';
import { Link } from "react-router-dom"

import committee from "../../../assets/matrimonyDashboardBtn/committee.png";
import rishtey from "../../../assets/matrimonyDashboardBtn/vaishyaRistrey.png";
import vaishyasamachar from "../../../assets/matrimonyDashboardBtn/vaishyasamachar.png";
import gatividhiya from "../../../assets/matrimonyDashboardBtn/vaishyaGatividhiya.png"
import profile from "../../../assets/matrimonyDashboardBtn/vaishyaProfile.png"
import ruls from "../../../assets/matrimonyDashboardBtn/rules.png"
// import gallery from "../../../assets/matrimonyDashboardBtn/gallery.png"
import vivahGallery from "../../../assets/matrimonyDashboardBtn/vivahgallery.png"
import Interest from "../../../assets/matrimonyDashboardBtn/interest.png"

import { HeaderBoarder } from '../../../Components';


import Ads from '../../../assets/MandirImg/chikitsa shivir.jpg';
import { useSelector } from 'react-redux';



function RetailereHomeDashboard() {

    const isAuthenticated = useSelector(state => state?.auth);
    const [isOpen, setOpen] = useState(true)

    // useEffect(() => {
    //     const hasModalBeenShown = sessionStorage.getItem('hasModalBeenShown');

    //     if (hasModalBeenShown) {
    //         setOpen(false); // Set isOpen to false if the modal has been shown before
    //     }
    //     //  else {
    //     // }
    // }, []);

    const images = useMemo(() => [
        // { filename: "mandirdarshan", file: mandirdarshan, url: "/mandirdarshan" },
        { filename: "committee", file: committee, url: "/committee" },
        // { filename: "members", file: members, url: "/members" },
        { filename: "profile", file: profile, url: "/profileEdit" },
        { filename: "vivahGallery", file: vivahGallery, url: "/vivahGallery" },
        { filename: "rishtey", file: rishtey, url: "/rishtey" },
        { filename: "Interest", file: Interest, url: "/intrest" },
        { filename: "vaishyasamachar", file: vaishyasamachar, url: "/news" },
        { filename: "gatividhiya", file: gatividhiya, url: "/gatividhiya" },
        { filename: "ruls", file: ruls, url: "/rules" },
        // { filename: "gallery", file: gallery, url: "/gallery" },

        // { filename: "bookdharmashala", file: bookdharmashala, url: "/bookdharmashala" },
        // { filename: "programs", file: programs, url: "/programs" },
        // { filename: "bloodbank", file: bloodbank, url: "/bloodbank" },
        // { filename: "donations", file: donations, url: "/donations" },
        // { filename: "achievements", file: achievements, url: "/achievements" },
        // { filename: "samajcalender", file: samajcalender, url: "/samajcalender" },
        // { filename: "digitalcard", file: digitalcard, url: "/digitalcard" },
        // { filename: "promotion", file: promotion, url: "/promotion" },
        // { filename: "complaints", file: complaints, url: "/suzhav" },
        // { filename: "invitations", file: invitations, url: "/invitations" },

        // { filename: "jobs", file: jobs, url: "/jobs" },
        // { filename: "careerGuidance", file: careerGuidance, url: "/careerGuidance" },
        // { filename: "businessprofile", file: businessprofile, url: "/businessprofile" },
        // { filename: "registration", file: registration, url: "/registration" },
        // { filename: "artical", file: artical, url: "/artical" },
        // { filename: "samajSewa", file: samajSewa, url: "/samajSewa" },
    ]);

    return (
        <>
            {/* <Header /> */}
            <HeaderBoarder heading={'RetailereHome डैशबोर्ड'} />
            <h1 className=' w-full text-center'>RetailereHome डैशबोर्ड</h1>
            {/* <ShowAds isOpen={isOpen} toggleModal={setOpen} /> */}
            {/* <div className='flex flex-wrap  items-start  px-5  '>
                <div className='flex flex-wrap  items-start sm:px-5 mb-5  '>
                    {images.map((image, index) => (
                        <Link to={'/matrimony' + image.url} key={index}>
                            <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" />
                        </Link>
                    ))}
                    {(isAuthenticated?.isLogin && isAuthenticated?.user?.userType === 1) && <div className='h-[5rem] md:h-[8rem] mt-2 mx-2 rounded-md bg-blue-700 text-white flex justify-center items-center'>
                        <Link className=' text-bold px-5 py-3 text-center ' to='/admin/matrimony' >matrimony Admin</Link>
                    </div>}
                </div>
            </div> */}
            {/* <Footer /> */}
        </>
    );
}

export { RetailereHomeDashboard }


const ShowAds = ({ isOpen, toggleModal }) => {
    const seen = () => {
        sessionStorage.setItem('hasModalBeenShown', 'true'); // Store in local storage that the modal has been shown
        toggleModal(false)
    }
    return (
        <>
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 flex justify-center items-center z-50 bg-gray-900 bg-opacity-50"
                >
                    <div className="relative flex flex-col items-center bg-white  max-w-sm  rounded-lg">
                        {/* Modal content */}
                        <button
                            onClick={seen}
                            type="button"
                            className="absolute top-0 right-0 text-3xl font-bold   bg-transparent   rounded-lg  inline-flex justify-center items-center"
                        >
                            <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Add your ad content here */}
                        {/* <div className='font-bold text-xl text-center my-2'>
                            <p className=' '> भारत के पहले पूर्ण डिजिटल समाज मैं  </p>
                            <p className=' '> आपका स्वागत है </p>
                        </div> */}
                        <img className=" w-64 h-[100%]" src={Ads} />
                    </div>
                </div>
            )}
        </>
    );
};

