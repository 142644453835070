// const api = 'http://192.168.0.116:8081'
// const client_url = "https://social-app-backend-xi.vercel.app/api/v1";
// const Domain = "https://social-app-backend-xi.vercel.app/"
// https://ayush.transdunia.com/api/v1/country
// production

const client_url = "https://ayush.transdunia.com/api/v1";
const Domain = "https://ayush.transdunia.com/"
const clientDomain = 'https://ayush.merasamaj.com/';

// const client_url = "http://localhost:9000/api/v1";
// const Domain = 'http://localhost:9000/';
// const clientDomain = 'http://localhost:9000/'

// const client_url = "https://nodenode.merasamaj.com/api/v1"
// const blog_api = `${client_url}/v1/blog`;
// const contact_api = `${client_url}/v1/contact`;
// const refresh_api = `${client_url}/v1/auth/refresh`;
// const signin_api = `${client_url}/v1/auth/signin`;
// const signup_api = `${client_url}/v1/auth/signup`;
// const google_api = `${client_url}/v1/auth/google`;
// const reset_password_api = `${client_url}/v1/auth/reset-password`;
// const set_password_api = `${client_url}/v1/auth/reset-password/set-password/token`;
// Get APis
// *****members ***

const get_member = `${client_url}/getMember`
// ******state city country*****
const get_country = `${client_url}/country`
const get_state = `${client_url}/states`
const get_city = `${client_url}/cities`
const get_citi_by_name = `${client_url}/cities/search`
const getMemberByBloodAndCity = `${client_url}/getMemberByBloodAndCity`
const searchMemberForMemberPage = `${client_url}/searchMemberForMemberPage`
// Post APIS
const post_member = `${client_url}/addmember`
const update_member = `${client_url}/updateMember`
//auth
const request_otp = `${client_url}/request-otp`
const verify_otp = `${client_url}/verify-otp`
export {
  client_url,
  get_member,
  get_country,
  get_state,
  get_city,
  post_member,
  getMemberByBloodAndCity,
  request_otp,
  verify_otp,
  update_member,
  get_citi_by_name,
  Domain,
  searchMemberForMemberPage,
  clientDomain
};
