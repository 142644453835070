import React, { useState } from 'react'



import img_1 from "../../../assets/commitCard/img_1.png"




// const CommitteeMember = [card1, card2, card3, card4, card5, card6, card7, card8, card9, card10, card11, card12, card13, card14, card15, card16, card17]
// const CommitteeMemberCertificate = [certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, certificate7, certificate8, certificate9, certificate10, certificate11, certificate12, certificate13, certificate14, certificate15, certificate16, certificate17]
// const commitImg = [img_1, img_2, img_3, img_4, img_5, img_6,]
const commitImg = [img_1,]



export default function Committee() {
    const [isOpen, setOpen] = useState(false);
    const [certificateIndex, setCertificateIndex] = useState(null);

    const show_certificate = (index) => {
        setCertificateIndex(index)
        setOpen(true)
    }

    return (
        <>
            {/* <ShowCertificate isOpen={isOpen} certificateIndex={certificateIndex} toggleModal={setOpen} /> */}
            <div className='    flex flex-wrap gap-3 justify-center '>
                <div className='    flex flex-wrap  gap-0 sm:gap-3  justify-center '>
                    {/* <CommitteeCard /> */}
                    {Array(36).fill(img_1).map((img, index) =>
                    (<div key={index} className='  bg-transparent rounded-md shadow-all-sides items-center flex flex-col mt-4 '>
                        <img key={index} className='w-full   md:w-[350px] xl:w-[450px] ' src={img_1} loading='lazy' />
                        {/* <button onClick={() => { show_certificate(index) }} className=' w-50 cursor-pointer px-3 py-1 rounded-3xl bg-blue-900 text-center text-white font-bold'>Certificate</button> */}
                    </div>
                    )
                    )}
                </div>
            </div>
            {/* {commitImg.map((img, index) => (
                <img src={img} key={index} className=" img-fluid" />
            ))} */}

        </>
    )



}



const ShowCertificate = ({ isOpen, toggleModal, certificateIndex }) => {
    const seen = () => {

        toggleModal(false);
    }

    return (
        <>
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 flex justify-center items-center z-[999] bg-gray-900 bg-opacity-50"
                >
                    <div className="relative flex flex-col items-center bg-white max-w-sm rounded-lg">
                        <button
                            onClick={seen}
                            type="button"
                            className="absolute top-0 right-0 text-3xl font-bold bg-transparent rounded-lg inline-flex justify-center items-center"
                        >
                            <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className=' p-5 text-center flex items-center flex-col'>
                            {/* <h1 className=' text-2xl text-red-500 font-bold'>{swagat_sms}</h1> */}
                            {/* <img className="w-full h-auto" src={CommitteeMemberCertificate[certificateIndex]} alt="Advertisement" /> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};






